<template>
  <div class="dialogBackground">
    <div class="dialogContainer">
      <transition name="datepicker">
        <div>
          <div class="pickerContainer">
            <div class="currentDate">
              <span style="margin-bottom: 5px; display: block">{{ title }}</span>
              {{ formattedInternalDate }}
            </div>
            <div class="dateSelection">
              <div class="monthSelection">
                <button class="material-icons" @click="prevMonth">
                  chevron_left
                </button>
                <p>{{ pickerHelper.toLocaleString('de-DE', {month: 'long'}) + ' ' + pickerHelper.getFullYear() }}</p>
                <button class="material-icons" @click="nextMonth">
                  chevron_right
                </button>
              </div>
              <div class="daySelection">
                <div v-for="weekday in 7" :key="'day-'+weekday" class="weekday">
                  {{ (new Date(2022, 7, weekday)).toLocaleString('de-DE', {weekday: 'short'}) }}
                </div>
                <div
                    v-for="spacer in calculateDayIndex(new Date(pickerHelper.getFullYear(),pickerHelper.getMonth(),1).getDay())"
                    :key="spacer">
                </div>
                <div v-for="d in new Date(pickerHelper.getFullYear(),pickerHelper.getMonth()+1,0).getDate()" :key="d"
                     class="singleDay"
                     @click="setInternalDate(new Date(pickerHelper.getFullYear(), pickerHelper.getMonth(),d,hourHelper,minuteHelper))"
                     :class="{'selected':isSameDate(new Date(dateHelper), new Date(pickerHelper.getFullYear(),pickerHelper.getMonth(),d))}">
                  <p>{{ d }}</p>
                </div>
              </div>
            </div>
            <div class="timeSelection" v-if="showTimePicker">
              <div class="incrementer">
                <button class="material-icons" @click="addHour">
                  add
                </button>
                <input v-model="hourHelper" type="number" maxlength="2" @keydown.enter="updateTime">
                <button class="material-icons" @click="subtractHour">
                  remove
                </button>
              </div>
              :
              <div class="incrementer">
                <button class="material-icons" @click="addMinute">
                  add
                </button>
                <input v-model="minuteHelper" type="number" maxlength="2" @keydown.enter="updateTime">
                <button class="material-icons" @click="subtractMinute">
                  remove
                </button>
              </div>
            </div>
            <div class="buttonRow">
              <PlainButton button-type="fluid" :button-function="cancel" is-slim is-small>
                Abbrechen
              </PlainButton>
              <PlainButton :button-function="finished" is-slim is-small>
                Fertig
              </PlainButton>
            </div>
          </div>
        </div>
      </transition>

    </div>
  </div>

</template>

<script>
import PlainButton from "@/components/Buttons/PlainButton";

export default {
  name: "DatepickerDialog",
  components: {PlainButton},
  props: {
    selectedDate: Date,
    title: {
      type: String,
      default: null,
    },
    showTimePicker: {
      type: Boolean,
      default: true
    },
    closingFunction: Function
  },
  computed: {
    formattedDate() {
      return this.showTimePicker ? this.selectedDate.toLocaleDateString('de-DE') + ' - ' + this.selectedDate.toLocaleTimeString('de-DE') : this.selectedDate.toLocaleDateString('de-DE')
    },
    formattedInternalDate() {
      return this.showTimePicker ? this.dateHelper.toLocaleDateString('de-DE') + ' - ' + this.dateHelper.toLocaleTimeString('de-DE') : this.dateHelper.toLocaleDateString('de-DE')
    },
  },
  data() {
    return {
      pickerHelper: new Date(),
      dateHelper: new Date(),
      hourHelper: 0,
      minuteHelper: 0
    }
  },

  watch: {
    selectedDate: {
      immediate: true,
      handler(newDate) {
        this.pickerHelper = new Date(newDate)
        this.dateHelper = new Date(newDate)
        this.hourHelper = newDate.getHours()
        this.minuteHelper = newDate.getMinutes()
      }
    }

  },
  methods: {
    isSameDate(selectedDate, datepickerDate) {
      return selectedDate.getDate() === datepickerDate.getDate() && selectedDate.getMonth() === datepickerDate.getMonth() && selectedDate.getYear() === datepickerDate.getYear()
    },
    calculateDayIndex(dayNumber) {
      if (dayNumber === 0) {
        dayNumber = 6
      } else {
        dayNumber = dayNumber - 1;
      }
      return dayNumber
    },
    updateTime() {
      this.dateHelper = new Date(this.dateHelper.getFullYear(), this.dateHelper.getMonth(), this.dateHelper.getDate(), this.hourHelper, this.minuteHelper)
    },
    setInternalDate(date) {
      this.dateHelper = new Date(date);
    },
    setDate(date) {
      this.$emit('update:selectedDate', date)
    },
    nextMonth() {
      this.pickerHelper = new Date(this.pickerHelper.setMonth(this.pickerHelper.getMonth() + 1))
    },
    reset() {
      this.pickerHelper = new Date(this.selectedDate)
      this.dateHelper = new Date(this.selectedDate)
      this.hourHelper = this.selectedDate.getHours()
      this.minuteHelper = this.selectedDate.getMinutes()
    },
    addHour() {
      if (this.hourHelper === 23) {
        this.hourHelper = 0;
      } else {
        this.hourHelper++;
      }
      this.updateTime()
    },
    subtractHour() {
      if (this.hourHelper === 0) {
        this.hourHelper = 23;
      } else {
        this.hourHelper--;
      }
      this.updateTime()
    },
    addMinute() {
      if (this.minuteHelper === 59) {
        this.minuteHelper = 0;
        this.addHour()
      } else {
        this.minuteHelper++;
      }
      this.updateTime()
    },
    subtractMinute() {
      if (this.minuteHelper === 0) {
        this.minuteHelper = 59;
        this.subtractHour()
      } else {
        this.minuteHelper--;
      }
      this.updateTime()
    },
    cancel() {
      this.reset()
      this.closingFunction(false);

    },
    finished() {
      this.setDate(this.dateHelper)
      this.closingFunction(true);
    },
    prevMonth() {
      this.pickerHelper = new Date(this.pickerHelper.setMonth(this.pickerHelper.getMonth() - 1))
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/themes.scss";

.dialogContainer {
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
}

.dialogBackground {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}

.datepicker-enter-active, .datepicker-leave-active {
  transition: 0.25s
}

.datepicker-enter-from {
  opacity: 0;
  transform: translateY(-50px);
}

.datepicker-leave-to {
  opacity: 0;
  transform: translateY(-50px);

}

.pickerButton {
  cursor: pointer;
  width: 100%;
  padding: 16px;
  font-family: 'Poppins', sans-serif;


  @include theme {
    color: theme-get('text-2');
    background-color: theme-get('input-background');
    box-shadow: theme-get('shadow-1');
  }
  font-size: 1.125em;
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none;

  &:hover {
    @include theme {
      background-color: theme-get('primary-background');
      color: theme-get('primary-color');
      box-shadow: theme-get('shadow-1');
    }
  }
}

.currentDate {

  border-radius: 12px 12px 0 0;
  text-align: left;
  font-size: 1.25em;
  font-weight: 500;

  div {
    font-size: 0.875em;
    margin: 0;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  @include theme {
    background-color: theme-get('navbar');
  }
  color: white;
  padding: 24px;
}

.pickerContainer {
  max-width: 270px;
  z-index: 70;
  align-self: center;
  @include theme {
    color: theme-get('text-2');
    background-color: theme-get('background-color-1');
    box-shadow: theme-get('shadow-1');
  }
  border-radius: 12px;
}

.monthSelection {
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    @include theme {
      color: theme-get('text-1');
    }

    &:hover {
      @include theme {
        color: theme-get('primary-color');
      }
    }
  }

  @include theme {
    color: theme-get('text-1');
  }

  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.daySelection {
  .weekday {
    justify-self: center;
    align-self: center;
    font-weight: 600;
    @include theme {
      color: theme-get('listheader');
    }
  }

  display: grid;
  padding: 0 16px;
  gap: 2px;
  grid-template-columns: repeat(7, 1fr);

  .singleDay {
    cursor: pointer;
    padding: 4px;
    border-radius: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 32px;
    margin: 0;
    line-height: 1;
    width: 32px;
    transition: 0.25s all;

    &.selected {
      @include theme {
        background-color: theme-get('primary-color');

      }
      color: white;


      &:hover {
        @include theme {
          outline: 1px solid theme-get('primary-color');
          background-color: theme-get('primary-background');
        }


      }
    }

    &:hover {
      @include theme {
        color: theme-get('primary-color');
        background-color: theme-get('primary-background');
      }
    }
  }
}

.timeSelection {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .incrementer {
    width: 100px;
    display: flex;
    flex-direction: column;

    button {
      cursor: pointer;
      border-radius: 4px;
      border: none;

      @include theme {
        color: theme-get('primary-color');
        background-color: theme-get('primary-background');
      }

      &:hover {
        @include theme {
          background-color: theme-get('primary-color');

        }

        color: #FFFFFF;
      }
    }

    input {
      font-family: 'Poppins', sans-serif;
      text-align: center;
      font-size: 1.5em;

      @include theme {
        color: theme-get('text-1');
        background-color: theme-get('background-color-1');
      }

      -moz-appearance: textfield;
      border: none;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.buttonRow {
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
  padding: 12px;
}
</style>
