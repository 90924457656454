<template>
  <div id="adminTournament">
    <FullscreenLoading v-if="alertType==='alert-loading'">
    </FullscreenLoading>

    <div id="title">
      <div class="tournamentPreHeader">
        <IconButton button-type="fluid" :button-function="()=>{$router.back()}">
      <span class="material-icons">
        chevron_left
      </span>
        </IconButton>
        <p>
          Turnier
        </p>
      </div>
      <div class="info">
        <h1>{{ tournament.title }}</h1>
        <div v-html="compiledDescription" class="description"/>
      </div>

      <PlainButton :button-function="()=>$router.push($route.params.tournament_id+'/update')" scale-by-child is-small
                   button-type="success">
        <span class="insideButton">
           <span class="material-icons">
              edit
          </span>
          <span>
              Bearbeiten
          </span>
        </span>


      </PlainButton>
    </div>

    <div id="details">
      <DetailElement icon="groups" title="Teilnehmer:"
                     :description="tournament?.teams?.length.toString().concat(' ',tournament?.team_size>1?(tournament?.teams?.length===1?'Team':'Teams'):'Spieler')"
                     v-if="tournament?.teams?.length>0"></DetailElement>
      <DetailElement icon="emoji_events" title="Preis:" v-if="tournament?.prize?.length>0"
                     :description="tournament?.prize"/>
      <DetailElement icon="event" title="Start:"
                     :description="formatDate(new Date(tournament?.datetime))"></DetailElement>
    </div>

    <div id="team">
      <div id="phase">
        <div id="phasecontent">
          <div id="phasedisplay">
            <h2>{{ tournament.current_registration_phase }}. Phase</h2>
            <div id="buttons">
              <PlainButton is-small is-slim button-type="normal" v-if="current_phase.phase<4"
                           :button-function="()=>newPhase(tournament.current_registration_phase+1)">
                Nächste Phase
                <span class="material-icons control">
                  chevron_right
                </span>

              </PlainButton>
              <div class="info" v-if="current_phase.phase===4">
                <span class="material-icons">emoji_events</span>
                <p>Um Turnier zu beenden, Sieger auswählen.</p>
              </div>


              <PlainButton is-small is-slim button-type="fluid" v-if="current_phase.phase>1&&current_phase.phase<5"
                           :button-function="()=>newPhase(tournament.current_registration_phase-1)">
                <span class="material-icons control">
                  chevron_left
                </span>
                Vorherige Phase
              </PlainButton>

              <DatepickerDialog v-if="datepicker.show" v-model:selected-date="datepicker.date" :isOpenInstantly="true"
                                :title="datepicker.title"
                                :closingFunction="(success)=>success?newPhase(tournament.current_registration_phase-1):cancelNewPhase()"
              ></DatepickerDialog>


            </div>
          </div>
          <div id="phasedetails">
            <h3>{{ current_phase.name }}</h3>
            <div id="abilities">
              <div class="phasedetail">
                <span class="material-icons" :class="current_phase.abilities.create_teams?'success':'error'">
                   {{ current_phase.abilities.create_teams ? 'check' : 'clear' }}
                </span>

                <p>
                  Teams erstellen
                </p>
              </div>
              <div class="phasedetail">

                <span class="material-icons" :class="current_phase.abilities.join_teams?'success':'error'">
                   {{ current_phase.abilities.join_teams ? 'check' : 'clear' }}
                </span>

                <p>
                  Teams beitreten
                </p>
              </div>
              <div class="phasedetail">

                <span class="material-icons" :class="current_phase.abilities.leave_teams?'success':'error'">
                   {{ current_phase.abilities.leave_teams ? 'check' : 'clear' }}
                </span>

                <p>
                  Teams verlassen
                </p>
              </div>
              <div class="phasedetail">

                <span class="material-icons" :class="current_phase.abilities.admin_set_absend?'success':'error'">
                   {{ current_phase.abilities.admin_set_absend ? 'check' : 'clear' }}
                </span>

                <p>
                  Spieler/Teams abwesend setzen
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="phaseindicators">
          <div :class="{'fill':current_phase.phase===1}"></div>
          <div :class="{'fill':current_phase.phase===2}"></div>
          <div :class="{'fill':current_phase.phase===3}"></div>
          <div :class="{'fill':current_phase.phase===4}"></div>
          <div :class="{'fill':current_phase.phase===5}"></div>
        </div>
      </div>
      <div class="teamList">
        <h2 v-if="tournament?.teams?.length>0">Teams</h2>
        <TeamItem v-for="team in tournament.teams" :team="team" :team_size="tournament.team_size" :key="team.team_id"
                  :show-btn="false" :is-winner="tournament.winner!==null&&tournament.winner.team_id===team.team_id"
                  show-winner-btn :set-winner-function="()=>showEndTournamentDialog(team.team_id)"
                  :show-more-button="[3,4].includes(current_phase.phase)" :setTeamAbsence="setTeamAbsence"
                  :goToTeamView="()=>this.$router.push('/admin/events/'+tournament.event_id+'/tournament/'+tournament.tournament_id+'/team/'+team.team_id)">
        </TeamItem>
      </div>
    </div>
    <transition name="error-show">
      <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
    </transition>
    <transition name="error-show">
      <SuccessPop :error-text="alertMessage" v-if="alertType==='alert-success'"></SuccessPop>
    </transition>


    <transition name="dialogBackground">
      <div class="dialogBackground"
           v-if=showWinnerWarningDialog
           @click="()=>{showWinnerWarningDialog===false; winner_id=0}"></div>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="Turnier abschließen" :is-warning="true" :show-close-button="true"
                   dialog-title="Gewinner auswählen"
                   :close-function="()=>{showWinnerWarningDialog=false; winner_id=0}"
                   :confirm-function="()=>this.setWinner(this.winner_id)" v-if="showWinnerWarningDialog">
        Wenn du dieses Team als Gewinner auswählst, wird das Turnier beendet und in Phase 5 gesetzt. Fortfahren?
      </ErrorDialog>
    </transition>
    <transition name="dateInPastDialog">
      <ErrorDialog button-text="Ok" :is-warning="false" :show-close-button="false"
                   dialog-title="Datum in der Vergangenheit"
                   :confirm-function="cancelNewPhase" v-if="showDateInPastDialog">
        Wenn du ein neues Datum setzt, muss dieses in der Zukunft sein
      </ErrorDialog>
    </transition>
  </div>
</template>

<script>

import IconButton from "@/components/Buttons/IconButton";
import TeamItem from "@/components/Tournaments/TeamItem";
import ErrorPop from "@/components/Popups/ErrorPop";
import SuccessPop from "@/components/Popups/SuccessPop";
import {adminService} from "@/services";
import {mapState} from "vuex";
import FullscreenLoading from "@/components/Indicators/Loading/FullscreenLoading";
import PlainButton from "../../../components/Buttons/PlainButton";
import DOMPurify from "dompurify";
import {marked} from "marked";
import DetailElement from "@/components/Tournaments/DetailElement";
import ErrorDialog from "@/components/Dialogs/ErrorDialog";
import DatepickerDialog from "@/components/Inputs/DatePickerDialog";

export default {
  name: "AdminTournament",
  components: {
    PlainButton,
    TeamItem,
    IconButton,
    ErrorPop,
    SuccessPop,
    FullscreenLoading,
    DetailElement,
    ErrorDialog,
    DatepickerDialog
  },
  data() {
    return {
      datepicker: {
        date: null,
        show: false,
        title: "",
      },
      tournament: {},
      phases: [
        {
          phase: 0,
          name: "Eventstart ausstehend",
          abilities: {
            create_teams: false,
            leave_teams: false,
            join_teams: false,
            admin_set_absend: false
          },

        },
        {
          phase: 1,
          name: "Offene Anmeldung",
          abilities: {
            create_teams: true,
            leave_teams: true,
            join_teams: true,
            admin_set_absend: false
          },

        },
        {
          phase: 2,
          name: "Teamerstellung geschlossen",
          abilities: {
            create_teams: false,
            leave_teams: true,
            join_teams: true,
            admin_set_absend: false
          },
        },
        {
          phase: 3,
          name: "Startvorbereitung",
          abilities: {
            create_teams: false,
            leave_teams: false,
            join_teams: false,
            admin_set_absend: true
          },
        },
        {
          phase: 4,
          name: "Spiel läuft",
          abilities: {
            create_teams: false,
            leave_teams: false,
            join_teams: false,
            admin_set_absend: true
          },
        },
        {
          phase: 5,
          name: "Spielende",
          abilities: {
            create_teams: false,
            leave_teams: false,
            join_teams: false,
            admin_set_absend: false
          },
        }

      ],
      winner_id: 0,
      showWinnerWarningDialog: false,
      showDateInPastDialog: false
    }
  },
  created() {
    this.fetchTournament()
  },
  methods: {
    fetchTournament() {
      adminService.fetchTournamentById(this.$route.params.tournament_id).then(tournament => {
        this.tournament = tournament;
      })
    },
    formatDate(date) {
      return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getUTCFullYear() + " - " + ((date.getHours()) % 24) + ":" + ('0' + date.getUTCMinutes()).slice(-2)
    },
    newPhase(newPhase) {
      this.datepicker.show = false;
      if (this.current_phase.phase > newPhase && this.datepicker.date === null && newPhase !== 2) {
        if (newPhase === 1) {
          this.datepicker.title = "Wähle neuen  Teamanmeldeschluss";
          this.datepicker.date = new Date(this.tournament.team_registration_closed);
        } else if (newPhase === 3) {
          this.datepicker.title = "Wähle neuen Turnierbeginn";
          this.datepicker.date = new Date(this.tournament.datetime);
        }
        this.datepicker.show = true;
        return
      }

      if (this.datepicker.date && this.datepicker.date < new Date()) {
        this.showDateInPastDialog = true;
        return;
      }

      if (this.current_phase.phase > newPhase && newPhase === 2) {
        this.datepicker.date = new Date(this.tournament.team_registration_closed);
      }

      this.reloadTournament(adminService.moveTournamentToPhase(this.tournament.tournament_id, newPhase, this.tournament.current_registration_phase, this.datepicker.date).then(res => {
        if (res) {
          if (this.current_phase.phase > newPhase && newPhase === 3) {
            this.tournament.current_registration_phase = newPhase;
            this.newPhase(2)
          }
          this.tournament.current_registration_phase = newPhase;
        }
        this.datepicker.date = null;
      }));

    },
    cancelNewPhase() {
      this.datepicker.date = null;
      this.datepicker.show = false;
      this.showDateInPastDialog = false;
    },
    showEndTournamentDialog(team_id) {
      this.showWinnerWarningDialog = true;
      this.winner_id = team_id;
    },
    setWinner(team_id) {
      this.reloadTournament(adminService.endTournament(this.tournament.tournament_id, team_id));
    },
    setTeamAbsence(team_id) {
      this.reloadTournament(adminService.setTeamAbsence(team_id));
    },
    reloadTournament(fun) {
      fun.then(() => {
            this.fetchTournament()
            this.showWinnerWarningDialog = false;
            this.winner_id = 0;
          }
      );
    }
  },
  computed: {
    ...mapState('alert', {
      alertMessage: 'message',
      alertType: 'type'
    }),
    compiledDescription() {
      let rawMD = this.tournament?.description;
      if (rawMD !== undefined && rawMD !== null) {
        rawMD = DOMPurify.sanitize(marked(rawMD));
      }
      return rawMD ?? '';
    },
    current_phase() {
      return this.phases[this.tournament.current_registration_phase] ?? this.phases[0]
    }

  }

}
</script>

<style scoped lang="scss">
@import "./src/styles/themes";

.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.dialogBackground-enter-active, .dialogBackground-leave-active {
  transition: opacity 0.25s;
}

.dialogBackground-enter-from, .dialogBackground-leave-to {
  opacity: 0;
}

.dialog-enter-active, .dialog-leave-active {
  transition: 0.25s ease;
}

.dialog-enter-to, .dialog-leave-from {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.dialog-enter-from, .dialog-leave-to {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(0);
}

#tournamentdetail {
  padding: 64px 128px;
  display: grid;
  grid-template-columns: 1fr 324px;
  grid-template-rows: auto auto;
  grid-template-areas:
  "title details"
  "list list";
  grid-gap: 48px;
}

.dialogContainer {
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
}

.dialogBackground {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}


#adminTournament {

  .tournamentPreHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    p {
      font-size: 1.5em;
      margin: 0;
      @include theme {
        color: theme-get(text-1)
      }
    }
  }

  padding: 64px 128px;
  display: grid;
  grid-template-columns: 1fr 324px;
  grid-template-rows: auto auto;
  grid-template-areas:
  "title details"
  "list list";
  grid-gap: 48px;
  text-align: left;

  .insideButton {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }

  #title {
    display: flex;
    flex-direction: column;
    gap: 24px;
    grid-area: title;

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      h1 {
        font-weight: 600;
        line-height: 1;
        font-size: 4em;
        margin: 0;
        @include theme {
          color: theme-get(header-2)
        }
      }

      .description :deep(p) {
        @include theme {
          color: theme-get('text-1');
        }
        overflow-wrap: break-word;
        font-size: 1.25em;
        text-align: left;
        margin: 0;
      }

      .description :deep(li) {
        @include theme {
          color: theme-get('text-1');
        }
        overflow-wrap: break-word;
        font-size: 1.25em;
        text-align: left;
      }

      .description :deep(h1) {
        @include theme {
          color: theme-get('text-1');
        }
        overflow-wrap: break-word;
        font-size: 2.5em;
        text-align: left;
        margin: 0;
      }

      .description :deep(h2) {
        @include theme {
          color: theme-get('text-1');
        }
        overflow-wrap: break-word;
        font-size: 1.75em;
        text-align: left;
        margin: 0;
      }

      .description :deep(h3) {
        @include theme {
          color: theme-get('text-1');
        }
        overflow-wrap: break-word;
        font-size: 1.5em;
        text-align: left;
        margin: 0;
      }
    }

  }

  #phase {
    #phasecontent {
      display: flex;
      gap: 64px;
    }

    display: flex;
    gap: 64px;
    justify-content: space-between;
    padding: 32px;
    border-radius: 16px;

    @include theme {
      box-shadow: theme-get('shadow-1');
      background-color: theme-get('background-color-1');
    }

    h2 {
      margin: 0;
      font-size: 48px;
      font-weight: 600;
      @include theme {
        color: theme-get('header-2');
      }
    }

    #phasedisplay {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: auto;

      #buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 12px;
        flex-wrap: wrap;

        .control {
          padding: 0 8px;
        }

        .info {
          display: flex;
          gap: 4px;

          .material-icons {
            @include theme {
              color: theme-get('primary-color');
            }
          }

          p {
            margin: 0;
            @include theme {
              color: theme-get('text-2');
            }
          }
        }

      }
    }

    #phasedetails {
      h3 {
        margin: 0;
        font-size: 36px;
        font-weight: 600;
        @include theme {
          color: theme-get('header-3')
        }
      }

      #abilities {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;
        column-gap: 24px;
        row-gap: 12px;

        .phasedetail {

          .error {
            color: #D55353;
          }

          .success {
            @include theme {
              color: theme-get('success')
            }
          }

          p {
            margin: 0;
            @include theme {
              color: theme-get('text-1')
            }
          }

          display: flex;
          gap: 4px;
          justify-content: left;
          align-items: center;
        }
      }

    }

    #phaseindicators {

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div {

        border-radius: 8px;
        height: 12px;
        width: 12px;
        content: "-";
        @include theme {
          border: 2px solid theme-get('primary-color');

        }

        &.fill {
          @include theme {
            background-color: theme-get('primary-color');

          }
        }
      }
    }
  }


  #details {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    grid-area: details;
  }

  #team {
    grid-area: list;

    .teamList {
      padding-top: 64px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    h2 {
      font-size: 3em;
      font-weight: 600;
      margin: 0;
      @include theme {
        color: theme-get('header-2')
      }
    }
  }

  .dialogBackground {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background-color: hsla(0, 0%, 0%, 0.5);
    backdrop-filter: blur(50px);
    width: 100%;
    height: 100%;
  }

}

@media screen and (max-width: 1400px) {
  #adminTournament {
    padding: 64px 64px;
  }
}

@media screen and (max-width: 1024px) {
  #adminTournament {
    align-items: center !important;
    display: flex;
    flex-direction: column;
    padding: 64px 32px;

    #team {
      width: 100%;
    }
  }
  #phase {
    #phasecontent {
      #phasedetails {
        #abilities {
          grid-template-columns: auto;
        }
      }
    }

  }
}

@media screen and (max-width: 768px) {

  #adminTournament {
    justify-content: center;

    #title {
      align-items: center;

      .info {
        align-items: center;

        .description :deep(p) {
          @include theme {
            color: theme-get('text-1');
          }
          font-size: 1em;
        }
      }

      h1 {
        text-align: center;
        font-size: 4em;
        margin-bottom: 16px;
      }

      p {
        text-align: center;
        font-size: 1em;
      }
    }

    #phase {
      flex-direction: column;

      #phasecontent {
        flex-direction: column;
        gap: 32px;

        #phasedisplay {
          gap: 4px;

          h2 {
            font-size: 2em;
          }

          justify-content: flex-start;
        }

        #phasedetails {
          gap: 4px;

          h3 {
            font-size: 1.5em;
          }

          #abilities {
            grid-template-columns: auto;
          }
        }
      }

      #phaseindicators {
        flex-direction: row;

        div {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

}


@media screen and (max-width: 480px) {

  #adminTournament {
    #title {
      .info {
        h1 {
          font-size: 3em;
          margin-bottom: 8px;
        }
      }

    }

    padding: 64px 16px;
  }
}
</style>
